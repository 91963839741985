import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({ baseUrl: 'http://localhost:3001' }),
    tagTypes: ['Users'],
    endpoints: builder => ({
        getUsers: builder.query({
            query: () => 'users',
            providesTags: ['Users'],
        }),
        getUsersById: builder.query({
            query: (id) => `users/${id}`,
            providesTags: ['Users'],
        }),
        getCrewcodes: builder.query({
            query: () => 'crewcodes',
            providesTags: ['Users'],
            select: 'crewcode',
        }),
        createUser: builder.mutation({
            query: ({ email, password, rank, crewcode, part, roster }) => ({
                url: 'users',
                method: 'POST',
                body: { email, password, rank, crewcode, part, roster },
            }),
            invalidatesTags: ['Users'],
        }),
        deleteUser: builder.mutation({
            query: (id) => ({
                url: `users/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Users'],
        }),
        updateUser: builder.mutation({
            query: ({ id, rank, roster, part, password }) => ({
                url: `users/${id}`,
                method: 'PUT',
                body: { rank, roster, part, password },
            }),
            invalidatesTags: ['Users'],
        }),
        updateUserPassword: builder.mutation({
            query: ({ id, crewcode, password }) => ({
                url: `users/${id}`,
                method: 'PUT',
                body: { id, crewcode, password },
            }),
            invalidatesTags: ['Users'],
        }),
        createSwap: builder.mutation({
            query: ({ tipoSwap, date, inicio, fin, razon, rank, crewcode, roster, userId }) => ({
                url: 'swaps',
                method: 'POST',
                body: { tipoSwap, date, inicio, fin, razon, rank, crewcode, roster, userId },
            }),
            invalidatesTags: ['Users'],
        }),
        deleteSwap: builder.mutation({
            query: ({ id }) => ({
                url: `swaps/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Users'],
        }),
        getSwaps: builder.query({
            query: () => 'swaps',
            providesTags: ['Swaps'],
        }),
        getSwapsDate: builder.query({
            query: (date) => `swaps/${date}`,
            providesTags: ['Swaps'],
        }),
        createConversation: builder.mutation({
            query: ({ senderId, receiverId }) => ({
                url: 'conversations',
                method: 'POST',
                body: { senderId, receiverId },
            }),
            invalidatesTags: ['Messages'],
        }),
        getConversations: builder.query({
            query: (id) => `conversations/${id}`,
            providesTags: ['Conversations'],
        }),
        getMessagesConversation: builder.query({
            query: (id) => `conversations/${id}`,
            providesTags: ['Messages']
        }),
        createMessageChat: builder.mutation({
            query: ({ sender, text, conversationId }) => ({
                url: 'messages',
                method: 'POST',
                body: { sender, text, conversationId },
            }),
            invalidatesTags: ['Messages'],
        }),
        deleteConversation: builder.mutation({
            query: ({ conversationId }) => ({
                url: `conversations/${conversationId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Users'],
        }),
    }),
});

export const { useGetUsersQuery, useCreateUserMutation, useDeleteUserMutation, useUpdateUserMutation, useGetCrewcodesQuery, useCreateSwapMutation, useGetSwapsQuery, useGetSwapsDateQuery, useGetUsersByIdQuery, useGetConversationsQuery, useGetMessagesConversationQuery, useCreateMessageChatMutation, useCreateConversationMutation, useDeleteSwapMutation, useDeleteConversationMutation,useUpdateUserPasswordMutation } = apiSlice;

export const apiReducer = apiSlice.reducer;

import React, { useState } from "react";
import { useGetUsersByIdQuery } from "../app/apiSlice";
import { useParams } from "react-router-dom";
import { useUpdateUserMutation } from "../app/apiSlice";
import { Link } from "react-router-dom";
import { useUpdateUserPasswordMutation } from "../app/apiSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function EditProfile() {
    const id = useParams()
    console.log(id.id)
    const navigate = useNavigate()

    const { data: swapData = [] } = useGetUsersByIdQuery(id.id)
    console.log(swapData)
    const [shown2, setShown2] = useState(false)
    const switchShown2 = () => setShown2(!shown2);
    const [shown, setShown] = useState(false)
    const switchShown = () => setShown(!shown);
    const [shownOld, setShownOld] = useState(false)
    const switchShownOld = () => setShownOld(!shownOld);
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const [passwordOld, setPasswordOld] = useState("")
    useEffect(() => {
        if (!id) {
            navigate("/login")
        }
    }, [])


    const [tipoRank, setTipoRank] = useState(swapData.rank)
    const [roster, setRoster] = useState(swapData.roster)
    const [part, setPart] = useState(swapData.part)
    const [updateUser] = useUpdateUserMutation();
    const [updatePassword] = useUpdateUserPasswordMutation()
    const [edit, setEdit] = useState(false)
    const [succes, setSucces] = useState(false)
    const [fail, setFail] = useState(false)

    

    const updateProfile = async () => {
        console.log("first")
        try {
            updateUser({ id: swapData._id, password: swapData.password, rank: tipoRank, roster: roster, part: part })
            setSucces(true)
            setTimeout(() => {
                navigate("/profile");
              }, 5000);
        }
        catch (err) {
            console.log(err)
        }
        //createUser({ email: email, password: password, crewcode: crewcode, rank: rank, part: part, roster: roster });

    }
    const editPassword = () => {

        if (password !== password2) {
            setFail(true)

            return
        }

        else {
            if (passwordOld !== swapData.password) {
                console.log("adios")
                setFail(true)
                return
            }
            else {
                updatePassword({ id: swapData._id, crewcode: swapData.crewcode, password: password })
                console.log("New pas")
                setEdit(false)
                setSucces(true)
                setFail(false)
                setTimeout(() => {
                    navigate("/profile");
                  }, 3000);
            }
        }

    }

    return (
        <>{swapData._id ? <div>

            <div>
                <select name="" id="" onChange={(e) => setTipoRank(e.target.value)} required defaultValue={swapData.rank}>
                    <option value="" disabled >Select option</option>
                    <option value="JU">JU</option>
                    <option value="JUPU">JUPU</option>
                    <option value="PU">PU</option>
                </select>
            </div>

            <div>
                Roster:
                <input type="number"
                    min="1"
                    max="16"
                    defaultValue={swapData.roster}
                    onChange={(e) => setRoster(e.target.value)}
                />
            </div>
            <div>
                Part time:
                <input type="checkbox" name="" id=""
                    defaultValue={part === true ? true : false}
                    checked={part}
                    onChange={(e) => setPart(e.target.checked)}

                />
            </div>
            
        </div> : <div>Loading</div>}


            <div>
                <button onClick={updateProfile}>Update</button>
                <div>
                    <button onClick={() =>{setEdit((prevState => !prevState)); setSucces(false)} }>Edit password</button>
                </div>
                <div>
                    {edit
                        ? <>

                            <div>
                                <div>
                                    Old password
                                </div>
                                <input type={shownOld ? 'text' : 'password'}
                                    value={passwordOld}
                                    placeholder="Old Password"
                                    onChange={(e) => {
                                        setPasswordOld(e.target.value);
                                        setFail(false);
                                    }}
                                    required />
                                <button onClick={switchShownOld} type="button">Show</button>
                            </div>
                            <div>
                                Password:
                            </div>
                            <div>
                                <input
                                    type={shown ? 'text' : 'password'}
                                    value={password}
                                    placeholder="New Password"
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                        setFail(false);
                                    }}
                                    required
                                />
                                <button onClick={switchShown} type="button">Show</button>
                            </div>
                            <div>
                                Repeat password:
                            </div>
                            <div>
                                <input
                                    type={shown2 ? 'text' : 'password'}
                                    value={password2}
                                    placeholder="Repeat New Password"
                                    onChange={(e) => {
                                        setPassword2(e.target.value);
                                        setFail(false);
                                    }}
                                    required
                                />
                                <button onClick={switchShown2} type="button">Show</button>

                            </div>
                            <button onClick={editPassword}>Update password</button>
                        </>
                        : <div>
                        </div>}
                </div>
                <div>
                    {succes
                        ? <div>Info changed correctly, redirecting to profile</div>
                        : <div></div>}
                </div>
                <div>{fail
                    ? <div>Password doesnt match or Old Password doesnt match</div>
                    : <div></div>}</div>
                <div>
                    <button><Link to={"/profile"}>
                        Cancel
                    </Link></button>
                </div>

            </div >

        </>
    )
}

export default EditProfile
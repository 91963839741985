import React, { useEffect, useState } from "react";
import { fetchUsers } from "../app/slices";
import { useDispatch } from "react-redux";
import { useGetUsersQuery, useCreateMessageChatMutation, useCreateConversationMutation, useGetUsersByIdQuery } from "../app/apiSlice";
import { fetchUsersInfo } from "../app/slices";
import { useNavigate } from "react-router-dom";

function PartFinder() {
    const id = localStorage.getItem("IDUserLogin")
    const id2 = JSON.parse(id)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [fetchData, setFetchData] = useState([])
    const [fetchDataFilter, setFetchDataFilter] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [newMessage, setNewMessage] = useState("")
    const [createMessage] = useCreateMessageChatMutation();
    const [errorConversation, setErrorConversation] = useState(false)
    const [createChat] = useCreateConversationMutation();
    const [succesConversation, setSuccesConversation] = useState(false)





    const { data: swapData = [] } = useGetUsersQuery()
    const { data: swapData2 = [] } = useGetUsersByIdQuery(id2)
    console.log(swapData2.part)

    useEffect(() => {
        if (!id) {
            navigate("/login")
        }
    }, [])


    useEffect(() => {
        const filteredData = swapData.filter(user => user.part === true);
        setFetchData(filteredData);
    }, [swapData]);

    console.log(fetchData)


    const todo = async (receiverId) => {
        setErrorConversation(false)
        setSuccesConversation(false)

        console.log("sadasd");
        console.log(receiverId);
        console.log(id2[0]);
        await createChat({ senderId: id2[0], receiverId: receiverId })

            .then((savedConversation) => {
                createMessage({ sender: id2[0], text: newMessage, conversationId: savedConversation.data._id })
                setNewMessage("")
                setIsOpen(false)
                setSuccesConversation(true)
            })
            .catch((error) => {
                console.error(error);
                setErrorConversation(true)
            });
    };

    const handleInputChange = (e, index) => {
        const { value } = e.target;
        setNewMessage((prevState) => ({ ...prevState, [index]: value }));
    };

    return (
        <>
            {fetchData.length > 0
                ? <div>

                    {fetchData.map((item, index) => {
                        return (
                            <>
                                <div>{item.crewcode}</div>
                                <div>Roster: {item.roster}</div>
                                <div>Rank: {item.rank}</div>

                                <button onClick={() => {
                                    setErrorConversation(false);
                                    setSuccesConversation(false);
                                    setIsOpen(prevState => ({ ...prevState, [index]: !prevState[index] }));
                                }}>Send a message</button> {
                                    isOpen[index] === true
                                        ? <>
                                            <div>
                                                <textarea name=""
                                                    id=""
                                                    cols="30"
                                                    rows="10"
                                                    placeholder="Write something..."
                                                    onChange={(e) => handleInputChange(e, index)}
                                                    value={newMessage[index] || ""}
                                                    required
                                                >
                                                </textarea>
                                                <button onClick={() => todo(item._id)}> Send </button>
                                            </div>
                                            {errorConversation
                                                ? <div>You have already a conversation</div>
                                                : <div></div>}

                                        </>
                                        : <></>

                                }


                            </>

                        )
                    })}
                    {succesConversation
                        ? <div>Message sent successfully</div>
                        : <div></div>}

                </div >
                : <div>No one is looking for Part time</div>
            }


        </>
    )
}
export default PartFinder
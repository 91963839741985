import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { Link } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios'
import { useCreateSwapMutation, useGetUsersByIdQuery } from '../app/apiSlice';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

function CreateSwap() {

  const [createSwap] = useCreateSwapMutation();
  const navigate = useNavigate();

  const [date, setDate] = useState()
  const [inicio, setInicio] = useState("")
  const [fin, setFin] = useState("")
  const [razon, setRazon] = useState("")
  const [tipoSwap, setTipoSwap] = useState("")
  const rank = localStorage.getItem('Rank');
  const id = JSON.parse(localStorage.getItem('IDUserLogin'));
  const id2 = localStorage.getItem("IDUserLogin")
  useEffect(() => {
    if (!id) {
      navigate("/login")
    }
  }, [])

  const { data: swapData = [] } = useGetUsersByIdQuery(id)
  const [swapDone, setSwapDone] = useState(false)


  const submitForm = (e) => {
    e.preventDefault();
    const data = { tipoSwap, date, inicio, fin, razon, rank, crewcode: swapData.crewcode };
    createSwap({ tipoSwap, date, inicio, fin, razon, rank, crewcode: swapData.crewcode, roster: swapData.roster, userId: id[0] })
    setSwapDone(true)
  }
  console.log(id)
  console.log(swapData.roster)
  return (<>
    <div>
      {swapDone === true
        ? <div>Swap created
          <div>
            <button onClick={() => navigate("/")}>Go to calendar</button>
          </div>
        </div>
        :
        <>
          <h1>{tipoSwap ? <div>{tipoSwap}</div> : <div>Choose the type</div>}</h1>
          <form onSubmit={submitForm}>
            <div>
              <select name="" id="" value={tipoSwap} onChange={(e) => setTipoSwap(e.target.value)} >
                <option value="" disabled >Select option</option>
                <option value="Swap">Swap</option>
                <option value="Offer">Offer Day Off</option>
                <option value="Another">Looking for operate</option>
              </select>
            </div>
            <div>
              Fecha:
              <input type="date" name="" id="" value={date} placeholder='' onChange={e => setDate(e.target.value)} />
            </div>
            <div>
              Hora inicio:
              <input type="time" name="" id="" value={inicio} onInput={e => setInicio(e.target.value)} />
            </div>
            <div>
              Hora final
              <input type="time" name="" id="" value={fin} onInput={e => setFin(e.target.value)} />
            </div>
            <div>
              Comentario
              <input type="text" name="" id="" value={razon} placeholder='Razon/dinero' onInput={e => setRazon(e.target.value)} />
            </div>
            <button type="submit">Submit</button>

          </form>
        </>
      }


    </div>


  </>)
}
export default CreateSwap
import React, { useEffect, useState } from "react";
import { useGetUsersByIdQuery } from "../app/apiSlice";
import { Link } from "react-router-dom";
import { fetchSwapsId } from "../app/slices";
import { useDispatch } from "react-redux";
import { useDeleteSwapMutation } from "../app/apiSlice";
import { useDeleteUserMutation } from "../app/apiSlice";
import { fetchSwaps } from "../app/slices";
import { useGetSwapsQuery } from "../app/apiSlice";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";


function Profile() {

    const id = JSON.parse(localStorage.getItem('IDUserLogin'));
    const id2 = localStorage.getItem("IDUserLogin")
    const dispatch = useDispatch()
    const { data: swapData = [] } = useGetUsersByIdQuery(id)
    console.log(swapData.part)
    console.log(swapData._id)
    const [rank, setRank] = useState("")
    const [editProfile, setEditProfile] = useState(false)
    const [editRank, setEditRank] = useState(false)
    const [disabledRank, setDisabledRank] = useState(false)
    const [editRoster, setEditRoster] = useState(true)
    const [roster, setRoster] = useState("")
    const [disabledRoster, setDisabledRoster] = useState(false)
    const navigate = useNavigate()

    const fetchSwapsInfo = dispatch(fetchSwaps())
    console.log(fetchSwapsInfo)
    const [fetchData, setFetchData] = useState([])
    const [deleteSwap] = useDeleteSwapMutation()
    const [deleteUser] = useDeleteUserMutation()
    const [freshSwaps, setFreshSwaps] = useState([]);
    const [expiredSwaps, setExpiredSwaps] = useState([]);
    const [fresh, setFresh] = useState(true)
    const [expired, setExpired] = useState(false)
    const [swaps, setSwaps] = useState(true)
    console.log("first")

    const { data: swapDataSwap = [] } = useGetSwapsQuery()
    console.log(swapDataSwap)
    const swapsID = swapDataSwap.filter(swap => swap.userId === swapData._id);
    console.log(swapsID)
    useEffect(() => {
        if (!id) {
            navigate("/login")
        }
    }, [])


    useEffect(() => {
        async function fetchSwapsInfo() {
            const data = await dispatch(fetchSwaps());
            console.log(data.payload)
            data.payload.sort((a, b) => new Date(a.date) - new Date(b.date));

            setFetchData(data.payload.filter(swap => swap.userId === swapData._id));

        }
        fetchSwapsInfo();
    }, [fetchSwaps, dispatch, swapData._id]);
    const logout = () => {
        localStorage.clear()
        navigate("/")
    }


    useEffect(() => {
        // filter the swaps/offers that are expired
        const now = new Date();
        const expired = fetchData.filter(item => new Date(item.date) < now && item.date !== new Date().toISOString().slice(0, 10));
        setExpiredSwaps(expired);
        // filter the swaps/offers that are not expired
        const fresh = fetchData.filter(item => new Date(item.date) >= now || item.date === new Date().toISOString().slice(0, 10));
        setFreshSwaps(fresh);
    }, [fetchData]);

    const deleteSwapFunction = async (id) => {
        console.log(id)
        await deleteSwap({ id }).unwrap();
        if (swaps) {
            setFreshSwaps((prevSwaps) => prevSwaps.filter((swap) => swap._id !== id));
        } else {
            setExpiredSwaps((prevSwaps) => prevSwaps.filter((swap) => swap._id !== id));
        }
    }
    const deleteUserFunction = async (id) => {
        console.log(id)
        await deleteUser(id).unwrap();
        localStorage.clear()
        navigate("/")

    }

    const [deleteUserWarning, setDeteleteUserWarning] = useState(false)


    return (

        <>
            <div>
                <div>
                    {swapData._id}
                </div>
                <div>
                    {swapData.crewcode}
                </div>
                <div>
                    Rank: {swapData.rank}
                </div>
                <div>
                    Roster: {swapData.roster}
                </div>
                <div>
                    {swapData.part ?
                        <div>You are looking for part time</div>
                        : <div>You are not looking for part time, you can edit it, by editing your profile info</div>}
                </div>

                <h1>Swaps:</h1>

                {swaps
                    ? <>
                        <button disabled>Fresh</button>
                        <button onClick={() => setSwaps(false)}>Expired</button>

                        {
                            freshSwaps.length > 0 ?
                                < div > Swaps / Offer day off:{freshSwaps.map((item) => {
                                    return (
                                        <><div>
                                            {item.tipoSwap}
                                        </div>
                                            <div>
                                                Date:{item.date}
                                            </div>
                                            <div>
                                                Start hour: {item.inicio}
                                            </div>
                                            <div>
                                                End hour: {item.fin}
                                            </div>
                                            <button onClick={() => { deleteSwapFunction(item._id) }}>Delete Swap</button>
                                        </>
                                    )
                                })}</div>
                                : <div>No Swaps</div>
                        }</>
                    : <>
                        <button onClick={() => setSwaps(true)}>Fresh</button>
                        <button disabled>Expired</button>
                        {
                            expiredSwaps.length > 0 ?
                                <div>Swaps/Offer day off:{expiredSwaps.map((item) => {
                                    return (
                                        <><div>
                                            {item.tipoSwap}
                                        </div>
                                            <div>
                                                Date:{item.date}
                                            </div>
                                            <div>
                                                Start hour: {item.inicio}
                                            </div>
                                            <div>
                                                End hour: {item.fin}
                                            </div>
                                            <button onClick={() => { deleteSwapFunction(item._id) }}>Delete Swap</button>
                                        </>
                                    )
                                })}</div>
                                : <div>No expired Swaps</div>
                        }</>
                }

            </div >
            <div >
                <button onClick={logout}>Log out</button></div>
            <div>
                <button>
                    <Link to={swapData._id}>
                        Edit profile INFO
                    </Link>
                </button>
            </div>
            <div>
                <button onClick={() => setDeteleteUserWarning(true)}>Delete User</button>
                {deleteUserWarning
                    ?
                    <div><button onClick={() => { deleteUserFunction(swapData._id) }}>Sure?</button></div>

                    : <div></div>}




            </div>


        </>
    )
}

export default Profile
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, NavLink } from "react-router-dom";
import axios from "axios";
import { useGetUsersQuery, useCreateUserMutation, useGetCrewcodesQuery } from "../app/apiSlice";
import { login } from "./authSlice";
import { fetchUsersLogin } from "../app/slices";
import { useUpdateUserPasswordMutation } from "../app/apiSlice";


const CreateUser = () => {
    const id = localStorage.getItem("IDUserLogin")

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [updatePassword] = useUpdateUserPasswordMutation()


    const [shown2, setShown2] = useState(false)

    const switchShown2 = () => setShown2(!shown2);
    const [shown3, setShown3] = useState(false)

    const switchShown3 = () => setShown3(!shown3);
    const [createUser] = useCreateUserMutation();

    const [succes, setSucces] = useState(false)


    const [recoverSucces, setRecoverSucces] = useState(false)

    const [registro, setRegistro] = useState(false);
    const [recover, setRecover] = useState(false)

    const [differentPassword, setDifferentPassword] = useState(false)
    const [crewcode, setCrewcode] = useState("");
    const [differentLogin, setDifferentLogin] = useState(true)
    const [rank, setTipoRank] = useState("")
    const [rankRecover, setTipoRankRecover] = useState("")

    const [failCrewcode, setFailCrewcode] = useState(false)
    const RankUser = localStorage.getItem('IDUserLogin');

    const [email, setEmail] = useState("")
    const [emailRecover, setEmailRecover] = useState("")

    const [password, setPassword] = useState("")
    const [passwordNew, setPasswordNew] = useState("")

    const [password2, setPassword2] = useState("")
    const [passwordNew2, setPasswordNew2] = useState("")


    const [crewcodeLogin, setCrewCodeLogin] = useState("")
    const [crewcodeRecover, setCrewCodeRecover] = useState("")

    const [passwordLogin, setPasswordLogin] = useState("")
    //console.log(users)
    const [roster, setRoster] = useState(1)
    const part = false
    const [errorRegister, setErrorRegister] = useState(false)
    const [recoverSuccesFail, setRecoverSuccesFail] = useState(false)
    const [recoverPasswordFail, setRecoverPasswordFail] = useState(false)



    const [sameCrewCode, setSameCrewCode] = useState(false)
    useEffect(() => {
        if (id) {
            navigate("/")
        }
    }, [])

    const addUser = async (e) => {
        e.preventDefault();

        if (password !== password2) {
            console.log("Password does not match")
            setDifferentPassword(true)
        } else if (crewcode.length !== 6) {
            setFailCrewcode(true)
        }
        else {
            try {
                await createUser({ email, password, crewcode, rank, part, roster });
                setEmail("");
                setPassword("");
                setPassword2("");
                setCrewcode("");
                setRegistro(false);
                setCrewCodeLogin(crewcode)
                setPasswordLogin(password)
                console.log("User was created successfully!");
            } catch (error) {
                console.error(error);
                setFailCrewcode(true);
            }
        }
    };

    const loginDatabase = () => {

        dispatch(fetchUsersLogin()).then((response) => {
            const userFilter = response.payload.find(
                (user) => user.crewcode === crewcodeLogin
            );

            if (userFilter) {
                if (userFilter.password === passwordLogin) {
                    setDifferentLogin(true);
                    console.log("Encontrado usuario y todo");
                    console.log(userFilter._id);
                    const storeIdLocalStorage = [userFilter._id];
                    const storeRankLocalStorage = userFilter.rank;
                    localStorage.setItem(
                        "IDUserLogin",
                        JSON.stringify(storeIdLocalStorage)
                    );
                    localStorage.setItem(
                        "Rank",
                        JSON.stringify(storeRankLocalStorage)
                    );

                    navigate("/");
                } else {
                    setDifferentLogin(false);
                    console.log("password no encontrada");
                }
            } else {
                setDifferentLogin(false);
                console.log("Nada encontrado");
            }
        });
    };
    const recoverPassword = () => {
        console.log("first")
        dispatch(fetchUsersLogin()).then((response) => {
            const userFilter = response.payload.find(
                (user) => user.crewcode === crewcodeRecover && user.rank === rankRecover && user.email === emailRecover
            );
            if (userFilter) {
                setRecoverSucces(true)
                console.log("funciona")
                setRecoverSuccesFail(false)
            }
            else {
                setRecoverSucces(false)
                setRecoverSuccesFail(true)
            }
        })
    }

    const editPassword = () => {

        if (passwordNew !== passwordNew2) {
            setRecoverPasswordFail(true)
            return
        }

        else {
            dispatch(fetchUsersLogin()).then((response) => {
                const userFilter = response.payload.find(
                    (user) => user.crewcode === crewcodeRecover && user.rank === rankRecover && user.email === emailRecover
                );
                if (userFilter) {
                    updatePassword({ id: userFilter._id, crewcode: userFilter.crewcode, password: passwordNew })
                    setPasswordNew("")
                    setPasswordNew2("")
                    setCrewCodeRecover("")
                    setEmailRecover("")
                    setRecoverSucces(false)
                    setRecover(false)
                    setSucces(true)
                    setRecoverPasswordFail(false)
                }
                else {
                    setRecoverSucces(false)
                }
            })


            //setEdit(false)
            //setSucces(true)
            //setFail(false)

        }

    }



    const handleInputChange = (event) => {
        const value = event.target.value.replace(/[^A-Za-z]/g, ""); // remove non-letter characters
        setCrewcode(value.toUpperCase()); // convert to uppercase
    };
    const handleInputChange2 = (event) => {
        const value = event.target.value.replace(/[^A-Za-z]/g, ""); // remove non-letter characters
        setCrewCodeLogin(value.toUpperCase()); // convert to uppercase
    };
    const handleInputChange3 = (event) => {
        const value = event.target.value.replace(/[^A-Za-z]/g, ""); // remove non-letter characters
        setCrewCodeRecover(value.toUpperCase()); // convert to uppercase
    };


    return (
        <div>

            {registro ? (
                <div>
                    <div>
                        <button onClick={() => setRegistro(false)}>Login</button>
                        <button disabled>Registro</button>
                    </div>
                    <div>
                        <div>
                            CrewCode:
                        </div>
                        <input
                            type="text"
                            value={crewcode}
                            placeholder="CrewCode"
                            onChange={handleInputChange}
                            maxLength="6"
                            minLength="6"
                            required
                        />
                        <div>
                            Email:
                        </div>
                        <input
                            type="text"
                            value={email}
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <div>
                            Password:
                        </div>
                        <div>
                            <input
                                type={shown2 ? 'text' : 'password'}
                                value={password}
                                placeholder="Password"
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button onClick={switchShown2} type="button">Show</button>
                        </div>
                        <div>
                            Repeat password:
                        </div>
                        <div>
                            <input
                                type={shown2 ? 'text' : 'password'}
                                value={password2}
                                placeholder="Password"
                                onChange={(e) => setPassword2(e.target.value)}
                                required
                            />
                            <button onClick={switchShown2} type="button">Show</button>

                        </div>
                        <div>
                            <select name="" id="" value={rank} onChange={(e) => setTipoRank(e.target.value)} required>
                                <option value="" disabled >Select option</option>
                                <option value="JU">JU</option>
                                <option value="JUPU">JUPU</option>
                                <option value="PU">PU</option>
                            </select>
                        </div>
                        <div>
                            <input
                                type="number"
                                value={roster}
                                placeholder="Roster"
                                onChange={(e) => setRoster(e.target.value)}
                                min="1"
                                max="16"
                                required
                            />

                        </div>
                        <button onClick={addUser}>Register</button>
                    </div>
                    {differentPassword ? <div>Password does not match</div> : <div></div>}
                    {errorRegister ? <div>Error in any field</div> : <div></div>}
                    {failCrewcode ? <div>Crewcode is on use</div> : <div></div>}

                </div>
            ) : (
                <div>
                    <div>
                        <button disabled>Login</button>
                        <button onClick={() => setRegistro(true)}>Registro</button>
                        <div>
                            CrewCode
                        </div>
                        <input type="text"
                            placeholder="CrewCode"
                            value={crewcodeLogin}
                            onChange={handleInputChange2}
                            maxLength="6"
                            minLength="6"
                        />
                    </div>
                    <div>
                        Password
                    </div>
                    <input
                        type={shown2 ? 'text' : 'password'}
                        placeholder="Password"
                        value={passwordLogin}
                        onChange={(e) => setPasswordLogin(e.target.value)}

                    />
                    <button onClick={switchShown2} type="button">Show</button>


                    <div>
                        <button onClick={loginDatabase}>Login</button>
                    </div>
                    <div>
                        <button onClick={() => setRecover(prevState => !prevState)}>Recover password</button>

                    </div>
                    {recover
                        ? <div>
                            <div>
                                CrewCode
                            </div>
                            <input type="text"
                                placeholder="CrewCode"
                                value={crewcodeRecover}
                                onChange={handleInputChange3}
                                maxLength="6"
                                minLength="6"
                            />
                            <div>
                                <div>Rank</div>
                                <select name="" id="" value={rankRecover} onChange={(e) => setTipoRankRecover(e.target.value)} required>
                                    <option value="" disabled >Select option</option>
                                    <option value="JU">JU</option>
                                    <option value="JUPU">JUPU</option>
                                    <option value="PU">PU</option>
                                </select>
                            </div>
                            <div>
                                Email:
                            </div>
                            <input
                                type="text"
                                value={emailRecover}
                                placeholder="Email"
                                onChange={(e) => setEmailRecover(e.target.value)}
                                required
                            />
                            <div><button onClick={recoverPassword}>Recover</button></div>
                            {recoverSucces ? <div>


                                <div>
                                    New Password:
                                </div>
                                <div>
                                    <input
                                        type={shown3 ? 'text' : 'password'}
                                        value={passwordNew}
                                        placeholder="Password"
                                        onChange={(e) => setPasswordNew(e.target.value)}
                                        required
                                    />
                                    <button onClick={switchShown3} type="button">Show</button>
                                </div>
                                <div>
                                    Repeat new password:
                                </div>
                                <div>
                                    <input
                                        type={shown3 ? 'text' : 'password'}
                                        value={passwordNew2}
                                        placeholder="Password"
                                        onChange={(e) => setPasswordNew2(e.target.value)}
                                        required
                                    />
                                    <button onClick={switchShown3} type="button">Show</button>

                                </div>
                                <button onClick={editPassword}>Edit password</button>


                            </div> : <div>{recoverSuccesFail
                                ? <div>Incorrect info</div>
                                : <div></div>}</div>}
                            {recoverPasswordFail ? <div>
                                Password does not match
                            </div> : <div></div>}
                        </div>
                        : <div></div>}
                    {differentLogin ? <div></div> : <div>Incorrect Crewcode/password</div>}
                    {succes
                        ? <div>Password changed it correctly</div>
                        : <div></div>}
                </div>
            )}

        </div>

    );
};
export default CreateUser;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


export const getSwaps = async () => {
    try {
        const response = await axios.get(`http://localhost:3001/swaps`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const fetchSwaps = createAsyncThunk("swaps/getSwaps", async () => {
    const response = await getSwaps();
    return response;
});
export const getUsers = async () => {
    try {
        const response = await axios.get(`http://localhost:3001/users`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const fetchUsersInfo = createAsyncThunk("users/getUsers", async () => {
    const response = await getUsers();
    return response;
});

export const getUsersLogin = async () => {
    try {
        const response = await axios.get(`http://localhost:3001/users`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const fetchUsersLogin = createAsyncThunk("users/getUsersLogin", async () => {
    const response = await getUsersLogin();
    return response;
});




export const getSwapsId = async (id) => {
    try {
        const response = await axios.get(`http://localhost:3001/swaps/${id}}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const fetchSwapsId = createAsyncThunk("swaps/getSwapsId", async (id) => {
    const response = await getSwapsId(id);
    return response;
});

export const getSwapsDate = async (date) => {
    try {
        const response = await axios.get(`http://localhost:3001/swaps/${date}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const fetchSwapsDate = createAsyncThunk("swaps/getSwapsDate", async (date) => {
    const response = await getSwapsDate(date);
    return response;
});


export const getConversation = async (id) => {
    try {
        const response = await axios.get(`http://localhost:3001/conversations/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const fetchConversation = createAsyncThunk("conversations/getConversation", async (id) => {
    const response = await getConversation(id);
    return response;
});




export const createUSer = async () => {
    try {
        const response = await axios.post(`http://localhost:3001/users`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const fetchUsers = createAsyncThunk("users/createUSer", async () => {
    const response = await createUSer();
    return response;
});

export const getInfoCrewcode = async (crewcode) => {
    try {
        const response = await axios.get(`http://localhost:3001/users?crewcode=${crewcode}`);
        return response.data;
    } catch (error) {
        console.error(error);
    }
};

export const fetchInfoCrewcode = createAsyncThunk("users/getInfoCrewcode", async (crewcode) => {
    const response = await getInfoCrewcode(crewcode);
    return response;
});

import React, { useEffect, useState } from "react";
import "./Test.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { useGetSwapsQuery } from "./app/apiSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchSwaps } from "./app/slices";


const Test = () => {
  const fecha = new Date();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [mes, setMes] = useState(fecha.getMonth());
  const [anio, setAnio] = useState(fecha.getFullYear());
  const [prueba, setPrueba] = useState([]);

  const id = JSON.parse(localStorage.getItem('IDUserLogin'));
  useEffect(() => {
    if (!id) {
      navigate("/login")
    }
  }, [])
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([])
  const { data: swapData = [] } = useGetSwapsQuery()
  const roster = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]


  useEffect(() => {
    const getSwaps = async () => {
      const response = await dispatch(fetchSwaps());
      setData(response.payload);
      setOriginalData(swapData)
    };
    getSwaps();
  }, [dispatch, swapData, setData, setOriginalData])


  useEffect(() => {
    crearMes();
  }, [mes, anio, data]);


  const crearMes = () => {
    const diasEnMes = new Date(anio, mes + 1, 0).getDate();
    const primerDiaEnSemana = new Date(anio, mes, 1).getDay();
    const primerDiaMostrado = (primerDiaEnSemana === 0) ? 6 : (primerDiaEnSemana - 1); // adjust the value to start on Monday
    const filas = [[]];

    for (let i = 0; i < primerDiaMostrado; i++) {
      filas[0].push("");
    }

    let dia = 1;
    for (let i = primerDiaMostrado; i < 7; i++) {
      filas[0].push(dia);
      dia++;
    }

    while (dia <= diasEnMes) {
      const ultimaFila = filas[filas.length - 1];
      if (ultimaFila.length === 7) {
        filas.push([]);
      }
      filas[filas.length - 1].push(dia);
      dia++;
    }

    setPrueba(filas);
  };

  const filterRoster = () => {
    const checkboxes = document.getElementsByName('roster');
    const checkedValues = [];

    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].checked) {
        checkedValues.push(checkboxes[i].value);
      }
    }

    if (checkedValues.length === 0) {
      // If no checkboxes are checked, assume the user wants to select all options
      setData(originalData);
      return;
    }

    const newDataFilterRoster = originalData.filter((dataRoster) => {
      return checkedValues.includes(dataRoster.roster);
    });

    setData(newDataFilterRoster);
    //console.log(newDataFilterRoster);
  }

  const mesSiguiente = () => {
    if (mes === 11) {
      setMes(0);
      setAnio(anio + 1);
    } else {
      setMes(mes + 1);
    }
  };

  const mesAnterior = () => {
    if (mes === 0) {
      setMes(11);
      setAnio(anio - 1);
    } else {
      setMes(mes - 1);
    }
  };

  
  return (
    <div className="calendario">
      <div className="mes">
        <div className="">
          <button className="botonIzq" onClick={mesAnterior}>
            Menos
          </button>
        </div>
        <div className="mesTexto">
          {new Date(anio, mes).toLocaleString("default", {
            month: "long",
          })}{" "}
          {anio}
        </div>
        <div className="">
          <button className="botonDer" onClick={mesSiguiente}>
            Más
          </button>
        </div>
      </div>
      <div>Select roster:


        {roster.map((item) => {
          return (
            <>
              <label>{item}
                <input type="checkbox" name="roster" id="roster-`${item}`" onChange={filterRoster} value={item} />
              </label>
            </>
          )
        })}

      </div>
      <table className="tabla">
        <thead>
          <tr>
            <th>L</th>
            <th>M</th>
            <th>X</th>
            <th>J</th>
            <th>V</th>
            <th>S</th>
            <th>D</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(prueba).map((key, i) => {
            return (
              <tr>
                {prueba[key].map((item, i) => {
                  const formattedItem = item ? item.toString().padStart(2, "0") : "";
                  const formattedMonth = (mes + 1).toLocaleString('en-US', { minimumIntegerDigits: 2 })
                  const cellDate = `${anio}-${formattedMonth}-${formattedItem}`;

                  const RankUser = localStorage.getItem('Rank');

                  const dataMatchSwap = data.find((dataItem) => {
                    if (JSON.parse(RankUser) === "JU") {
                      return dataItem.date === cellDate && (dataItem.rank === RankUser || dataItem.rank === `"JUPU"`) && dataItem.tipoSwap === "Swap";
                    } else if (JSON.parse(RankUser) === "JUPU") {
                      return dataItem.date === cellDate && (dataItem.rank === `"JU"` || dataItem.rank === RankUser || dataItem.rank === `"PU"`) && dataItem.tipoSwap === "Swap";
                    } else if (JSON.parse(RankUser) === "PU") {
                      return dataItem.date === cellDate && (dataItem.rank === `"JUPU"` || dataItem.rank === RankUser) && dataItem.tipoSwap === "Swap";
                    } else {
                      // handle error case
                      return false;
                    }
                  });
                  const bgColorSwap = dataMatchSwap ? "blue" : "white";

                  const dataMatchOffer = data.find((dataItem) => {
                    if (JSON.parse(RankUser) === "JU") {
                      return dataItem.date === cellDate && (dataItem.rank === RankUser || dataItem.rank === `"JUPU"`) && dataItem.tipoSwap === "Offer";
                    } else if (JSON.parse(RankUser) === "JUPU") {
                      return dataItem.date === cellDate && (dataItem.rank === `"JU"` || dataItem.rank === RankUser || dataItem.rank === `"PU"`) && dataItem.tipoSwap === "Offer";
                    } else if (JSON.parse(RankUser) === "PU") {
                      return dataItem.date === cellDate && (dataItem.rank === `"JUPU"` || dataItem.rank === RankUser) && dataItem.tipoSwap === "Offer";
                    } else {
                      // handle error case
                      return false;
                    }
                  });

                  const bgColorOffer = dataMatchOffer ? "green" : "white";


                  const dataMatchAnother = data.find((dataItem) => {
                    if (JSON.parse(RankUser) === "JU") {
                      return dataItem.date === cellDate && (dataItem.rank === RankUser || dataItem.rank === `"JUPU"`) && dataItem.tipoSwap === "Another";
                    } else if (JSON.parse(RankUser) === "JUPU") {
                      return dataItem.date === cellDate && (dataItem.rank === `"JU"` || dataItem.rank === RankUser || dataItem.rank === `"PU"`) && dataItem.tipoSwap === "Another";
                    } else if (JSON.parse(RankUser) === "PU") {
                      return dataItem.date === cellDate && (dataItem.rank === `"JUPU"` || dataItem.rank === RankUser) && dataItem.tipoSwap === "Another";
                    } else {
                      // handle error case
                      return false;
                    }
                  });

                  //  const dataMatchAnother = data.find(
                  //  (dataItem) => dataItem.date === cellDate && dataItem.rank === RankUser && dataItem.tipoSwap === "Another"
                  //);
                  const bgColorAnother = dataMatchAnother ? "red" : "white";


                  return (
                    <th key={key + i} className="dia">
                      <div style={{ height: "100%", position: "relative" }}>


                        {dataMatchAnother ? <Link to={`/swap/${cellDate}`}>
                          <div style={{ height: "60%", backgroundColor: bgColorAnother, position: "absolute", bottom: 0, left: 0, right: 0 }} ></div>
                        </Link>
                          : <div style={{ height: "60%", backgroundColor: "white", position: "absolute", bottom: 0, left: 0, right: 0 }}></div>
                        }

                        {
                          dataMatchOffer ? <Link to={`/swap/${cellDate}`}>

                            <div style={{ height: "40%", backgroundColor: bgColorOffer, position: "absolute", bottom: 0, left: 0, right: 0 }}></div>
                          </Link>

                            : <div style={{ height: "40%", backgroundColor: "white", position: "absolute", bottom: 0, left: 0, right: 0 }}></div>
                        }
                        {dataMatchSwap ? <Link to={`/swap/${cellDate}`}>

                          <div style={{ height: "20%", backgroundColor: bgColorSwap, position: "absolute", bottom: 0, left: 0, right: 0 }}></div>
                        </Link> : <div style={{ height: "20%", backgroundColor: "white", position: "absolute", bottom: 0, left: 0, right: 0 }}></div>}

                        <div>
                          {formattedItem !== 0 && item}

                        </div>
                      </div>
                    </th>

                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      
    </div >
  );
};

export default Test;

import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import Test from './Test';
import Navigation from './components/Navigation'
import CreateSwap from './components/CreateSwap';
import InfoSwap from './components/InfoSwap';
import CreateUser from './components/CreateUser';
import Profile from "./components/Profile"
import EditProfile from "./components/EditProfile.js"
import Messenger from './components/messenger/Messenger';
import PartFinder from './components/PartFinder';

function App() {
    return (
        <Router>
            <Navigation />

            <div className="container p-4">
                <Routes>

                    <Route path="/" element={<Test />} />

                    <Route path="/create" element={<CreateSwap />} />
                    <Route path="/swap/:cellDate" element={<InfoSwap />} />
                    <Route path="/login" element={<CreateUser />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path='/profile/:id' element={<EditProfile />} />
                    <Route path="/messenger" element={<Messenger />} />
                    <Route path="/part" element={<PartFinder />} />






                </Routes>

            </div>
        </Router>

    )
}
export default App